<template>
    <div style="width: 100%;">
        <div class="about-title">平台介绍</div>
        <div class="about-bg">
            <div class="about-content">
                <div class="about-content-div">连云港科技大市场平台于2021年10月开始建设，是连云港高新区管委会以服务“高新技术产业，培育高新技术企业”为落脚点，形成“资源汇聚、供需链接、服务撮合、技术交易、成果转化”五位一体的核心功能，采用线上线下结合的方式，集技术交易服务、高校科技成果、区域创新管理和监控分析、第三方服务机构线上服务等建立的一体化多元开放平台。</div>
                <div class="about-content-div">线下由南京理工大学连云港研究院负责运营管理，为连云港科技大市场建设提供配套服务，主要设置有行政人事、财务、信息平台管理、物业服务、招商等功能，负责平台物理空间管理、服务机构的招商和入驻管理、日常设施保障、服务机构的评级评价、数据系统维护等工作。</div>
                <div class="about-content-div">线上由引入的服务机构做运营支撑，实现平台科技金融、技术交易、大仪共享、技术转移、知识产权、军民融合、创业服务、人才培训等服务功能；由入驻的科技服务机构，提供财税咨询、法律服务等常规科技服务补充科技大市场常规科技服务功能，作日常服务支撑。</div>
            </div>
            <img src="@/assets/image/index/about/bg.png" alt="">
            <div class="bg1"></div>
            <div class="bg2"></div>
        </div>
        <div class="contact-title">联系方式</div>
        <div class="contact-way-list">
            <div class="way-item">
                <img class="img-1" src="@/assets/image/index/about/address.png" alt="">
                <div class="way-item-title">地址</div>
                <div class="way-item-info">江苏省连云港市海州区</div>
                <div class="way-item-info way-item-infosec">花果山大道17号1号楼2211室</div>
            </div>
            <div class="way-item">
                <img class="img-1" src="@/assets/image/index/about/code.png" alt="">
                <div class="way-item-title">邮编</div>
                <div class="way-item-info" style="margin-top: 41px;">222000</div>
            </div>
            <div class="way-item">
                <img class="img-2" src="@/assets/image/index/about/wx.png" alt="">
                <img class="img-3" src="@/assets/image/index/about/mp.png" alt="">
                <div class="way-item-info" style="margin-top: 13px;">扫一扫，关注官方公众号</div>
            </div>
            <div class="way-item">
                <img class="img-1" src="@/assets/image/index/about/phone.png" alt="">
                <div class="way-item-title">服务热线</div>
                <div class="way-item-info" style="margin-top: 41px;">0518-81086771</div>
            </div>
            <div class="way-item">
                <img class="img-1" src="@/assets/image/index/about/support.png" alt="">
                <div class="way-item-title">技术支持</div>
                <div class="way-item-info" style="margin-top: 41px;">18636524785</div>
            </div>
        </div>
        <div style="text-align: center">
            <img src="@/assets/image/index/about/baidu.png" style="width: 1200px;height: 500px;margin: 70px auto"
                 alt="">
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Aboutus",
        computed: {
            ...mapGetters({
                token: 'userStore/token',
                userName: 'userStore/userName',
                userId: 'userStore/userId',
                enterpriseState: 'userStore/enterpriseState'
            }),
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    .about-title {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 73px;
        text-align: center;
        margin-top: 50px;
    }

    .about-bg {
        width: 1032px;
        height: 471px;
        position: relative;
        margin: 10px auto 0;

        .about-content{
            position: absolute;
            top: 67px;
            left: 51px;
            z-index: 4;
            width: 931px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            .about-content-div{
                text-indent: 2em;
            }
        }

        img {
            width: 1032px;
            height: 471px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
        }

        .bg1 {
            position: absolute;
            top: 38px;
            left: -34px;
            width: 1100px;
            height: 395px;
            background: #0F61DF;
            opacity: 0.2;
            border-radius: 10px;
            z-index: 2;
        }

        .bg2 {
            position: absolute;
            top: 82px;
            left: -84px;
            width: 1200px;
            height: 327px;
            background: #0F61DF;
            opacity: 0.1;
            border-radius: 10px;
        }

    }

    .contact-title {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        margin: 51px 0 52px;
        text-align: center;
    }

    .contact-way-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
    }

    .way-item {
        width: 224px;
        height: 240px;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0 6px 20px -6px rgba(0, 0, 0, .2);
        text-align: center;

        .img-1 {
            width: 46px;
            height: 46px;
            margin-top: 37px;
        }

        .img-2 {
            width: 46px;
            height: 46px;
            display: block;
            margin: 24px auto 0;
        }

        .img-3 {
            width: 121px;
            height: 120px;
            margin-top: 5px;
        }

        .way-item-title {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            height: 18px;
            line-height: 18px;
            margin-top: 42px;
        }

        .way-item-info {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            height: 18px;
            line-height: 18px;
            margin-top: 20px;
        }

        .way-item-infosec {
            margin-top: 5px !important;
        }
    }
</style>
